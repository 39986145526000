<template>
  <v-app>
    <v-app-bar app color="grey darken-3" dark>
      <img src="./assets/advantech-logo.svg" />
    </v-app-bar>

    <v-main>
      <Intro />

      <v-container id="status">
        <!-- <div v-for="item in status" :key = "item.id"> -->

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 September 16 </v-card-subtitle>
          <v-card-text class="text--primary">
            There was a brief outage lasting several minutes on the
            WebAccess/DMP platform. The issue has been fully resolved, and
            normal operations have been restored.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 September 12 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you that new version of WebAccess/DMP 3.1.2
            (<a href="https://www.wadmp3.com">www.wadmp3.com</a>) has been
            released. Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/gen3/release-notes/">here</a>.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 September 10 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of
            WebAccess/DMP version 3.1.2 (<a href="https://www.wadmp3.com"
              >www.wadmp3.com</a
            >), scheduled for Thursday, September 12th, 2024. Please be aware
            that as we roll out the new version, there may be some intermittent
            downtimes throughout the day. We apologize for any inconvenience
            this may cause and want to assure you that our team will work
            diligently to minimize any disruptions.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 August 12 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you that new version of WebAccess/DMP 3.1.1
            (<a href="https://www.wadmp3.com">www.wadmp3.com</a>) has been
            released. Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/gen3/release-notes/">here</a>.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 August 9 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of
            WebAccess/DMP version 3.1.1 (<a href="https://www.wadmp3.com"
              >www.wadmp3.com</a
            >), scheduled for Monday, August 12th, 2024. Please be aware that as
            we roll out the new version, there may be some intermittent
            downtimes throughout the day. We apologize for any inconvenience
            this may cause and want to assure you that our team will work
            diligently to minimize any disruptions.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 August 1 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.5.6, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/gen2/release-notes/">here</a>.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 July 31 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of
            WebAccess/DMP version 2.5.6 scheduled for Thursday, August 1st,
            2024. Please be aware that as we roll out the new version, there may
            be some intermittent downtimes throughout the day. We apologize for
            any inconvenience this may cause and want to assure you that our
            team will work diligently to minimize any disruptions.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 July 24 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you that a new version of WebAccess/DMP
            3.1.0 (<a href="https://www.wadmp3.com">www.wadmp3.com</a>) has been
            released. Details can be found in the Release Notes, which are
            available
            <a href="https://docs.wadmp.com/gen3/release-notes/">here</a>.
            <br />
            To fully enjoy the features and improvements of WebAccess/DMP 3.1.0,
            please ensure that your client is upgraded to version 3.1.0.
            <br />
            Additionally, please be aware that the WebAccess/DMP 2.x.x version
            will be discontinued by the end of this year. We strongly recommend
            migrating your devices to the 3.x.x version.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 July 19 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of
            WebAccess/DMP version 3.1.0 (<a href="https://www.wadmp3.com"
              >www.wadmp3.com</a
            >), scheduled for Monday, July 22nd, 2024. Please be aware that as
            we roll out the new version, there may be some intermittent
            downtimes throughout the day. We apologize for any inconvenience
            this may cause and want to assure you that our team will work
            diligently to minimize any disruptions.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 February 23 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you that new version of WebAccess/DMP 3.0.3
            (<a href="https://www.wadmp3.com">www.wadmp3.com</a>) has been
            released. Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/gen3/release-notes/">here</a>.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2024 February 22 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of
            WebAccess/DMP version 3.0.3 (<a href="https://www.wadmp3.com"
              >www.wadmp3.com</a
            >), scheduled for Friday, February 23rd, 2024. Please be aware that
            as we roll out the new version, there may be some intermittent
            downtimes throughout the day. We apologize for any inconvenience
            this may cause and want to assure you that our team will work
            diligently to minimize any disruptions.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 December 16 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you that new versions of WebAccess/DMP 2.5.5
            (<a href="https://www.wadmp.com">www.wadmp.com</a>) and
            WebAccess/DMP 3.0.2 (<a href="https://www.wadmp3.com"
              >www.wadmp3.com</a
            >) have been released. Details can be found in Release Notes, which
            are available
            <a href="https://docs.wadmp.com/gen2/release-notes/"
              >here (for 2.5.5)</a
            >
            and
            <a href="https://docs.wadmp.com/gen3/release-notes/"
              >here (for 3.0.2)</a
            >.<br />

            <strong
              >These versions support migration of your data from version 2.x.x
              to version 3.x.x - please see the
              <a
                href="https://docs.wadmp.com/gen3/tutorials/migration-gen2-gen3/"
                >migration tutorial</a
              >.</strong
            ><br />

            Please note, that WebAccess/DMP client version 2.x.x is only
            compatible with <a href="https://www.wadmp.com">www.wadmp.com</a>,
            while WebAccess/DMP client version 3.x.x is only compatible with
            <a href="https://www.wadmp3.com">www.wadmp3.com</a>.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 December 14 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of
            WebAccess/DMP version 2.5.5 (<a href="https://www.wadmp.com"
              >www.wadmp.com</a
            >) and WebAccess/DMP version 3.0.2 (<a href="https://www.wadmp3.com"
              >www.wadmp3.com</a
            >), scheduled for Friday, December 15th, 2023. Please be aware that
            as we roll out the new version, there may be some intermittent
            downtimes throughout the day. We apologize for any inconvenience
            this may cause and want to assure you that our team will work
            diligently to minimize any disruptions.<br />
            <strong
              >Important note. The WebAccess/DMP client version 3.0.1, available
              in WebAccess/DMP 2.5.5, is only intended for version 3.x.x. of
              WebAccess/DMP. By installing this version of WebAccess/DMP Client,
              the device will no longer be able to connect to WebAccess/DMP
              2.x.x instance.</strong
            >
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 September 18 </v-card-subtitle>
          <v-card-text class="text--primary">
            We want to inform you about a recent technical issue that
            temporarily impacted the connectivity of routers to our platform.
            The issue did not affect the routers themselves; rather, it only
            affected their connection to the WebAccess/DMP servers. We are
            pleased to inform you that this issue has already been resolved.
            However, we'd like to bring to your attention that, owing to the
            introduction of new bootstrap logic in the latest client versions,
            there may be a delay of up to 3 days for some routers to fully
            reconnect to the platform. This is due to a gradual extension of the
            time between two recent connection attempts. We deeply appreciate
            your patience and trust in us during this period, and we sincerely
            apologize for any inconvenience this may have caused.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 July 10 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.5.4, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 July 7 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to inform you about the upcoming release of WADMP
            version 2.5.4 scheduled for next Monday, July 10th, 2023. Please be
            aware that as we roll out the new version, there may be some
            intermittent downtimes throughout the day. We apologize for any
            inconvenience this may cause and want to assure you that our team
            will work diligently to minimize any disruptions.
          </v-card-text>
        </v-card>

        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 May 25 </v-card-subtitle>
          <v-card-text class="text--primary">
            Please note that tomorrow, 2023 May 26, our systems might be
            temporarily unavailable from 12:00 to 18:00 CET due to maintenance
            work.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 May 3 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.5.3, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 April 28 </v-card-subtitle>
          <v-card-text class="text--primary">
            We are excited to announce the upcoming release of WADMP version
            2.5.3 next Wednesday (3.5.2023). Please note that as we roll out the
            new version next Wednesday, you might encounter some intermittent
            downtimes throughout the day. We apologize for any inconvenience
            this may cause and assure you that our team will work diligently to
            minimize any disruptions.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 April 14 </v-card-subtitle>
          <v-card-text class="text--primary">
            Dear valued users,<br />
            We apologize for the inconvenience caused by the recent WADMP
            outage. The outage was due to a database corruption issue that has
            been resolved.<br />
            The system is now fully functional.<br />
            In case you haven't received any recent emails from us and are
            facing difficulties logging into the system, please reach out to
            <a href="mailto:wadmp@advantech.com">wadmp@advantech.com</a> for
            further assistance.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 April 13 </v-card-subtitle>
          <v-card-text class="text--primary">
            We would like to give you an update on the current service outage.
            Our team is working tirelessly to restore all services, and we are
            making headway in resolving the issue. We recognize that this outage
            has been disruptive, and we apologize for any inconvenience it may
            have caused. Please rest assured that we are doing everything we can
            to restore everything to normal as soon as possible.
            <strong
              >Our aim is to have all services up and running by Friday</strong
            >
            (April 14).
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 April 13 </v-card-subtitle>
          <v-card-text class="text--primary">
            As a follow-up to our previous message, we want to update you on the
            status of the service outage. Our team is still actively working to
            restore everything and we are making progress toward resolving the
            issue. We understand that this outage has disrupted your normal
            operations, and we want to assure you that we're doing everything we
            can to get everything back to normal as soon as possible. We
            appreciate your patience and understanding as we work through this
            issue. We will continue to provide updates via this channel, so
            please stay tuned for more information. If you have any questions or
            concerns, please don't hesitate to contact our support team.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 April 13 </v-card-subtitle>
          <v-card-text class="text--primary">
            We're currently experiencing a service outage and are actively
            working to restore everything as soon as possible. Updates will be
            posted here once the issue is resolved. Thank you for your patience.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 April 7 </v-card-subtitle>
          <v-card-text class="text--primary">
            We are aware of issues with monitoring data. We are working on it.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 March 30 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.5.2, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2023 March 21 </v-card-subtitle>
          <v-card-text class="text--primary">
            It's possible that you have encountered a loss of monitoring data
            for approximately two hours (around 2:00 to 4:00 CET). We apologize
            for any inconvenience caused. During this time, the system
            experienced memory problems which led to the loss of monitoring
            data. However, we promptly resolved the issue.
          </v-card-text>
        </v-card>
        <!--<v-card class="pa-6 ma-2">      
          <v-card-subtitle class="pb-0">
            2022 December 6
          </v-card-subtitle>        
          <v-card-text class="text--primary">        
            There is a maintenance event planned for today, during which there may occur a short gap (a few minutes) in storing monitoring data from devices. 
          </v-card-text>        
    </v-card>-->
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 November 15 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.5.1, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 November 3 </v-card-subtitle>
          <v-card-text class="text--primary">
            You might have experienced an issue with the Web UI unavailability.
            This was caused by DNS issues of our service provider. Access to
            WADMP Web UI has been restored.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 October 17 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.5.0, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 October 17 </v-card-subtitle>
          <v-card-text class="text--primary">
            We are going to improve our servers today and release a new, 2.5.0,
            version of the WebAccess/DMP. This may lead to some short-term
            outages of the WebAccess/DMP service. We apologize for the
            inconvenience.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 September 1 </v-card-subtitle>
          <v-card-text class="text--primary">
            You might have experienced a connectivity issue due to certificate
            expiration. The issue has been already fixed, the service was
            restored to normal operating condition.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 July 28 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.4.4, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 July 28 </v-card-subtitle>
          <v-card-text class="text--primary">
            The issue with the delivery of emails was solved.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 July 27 </v-card-subtitle>
          <v-card-text class="text--primary">
            We are aware of an issue with delivery of emails. We are working on
            it.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 June 27 </v-card-subtitle>
          <v-card-text class="text--primary">
            You might have experienced a problem with login to the
            WebAccess/DMP. We discovered the problem quickly and worked our
            best, so the service was restored to normal operating condition in
            30 minutes.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 June 7 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.4.3, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 June 7 </v-card-subtitle>
          <v-card-text class="text--primary">
            We are going to improve our servers today and release a new, 2.4.3,
            version of the WebAccess/DMP. This may lead to some short-term
            outages of the WebAccess/DMP service. We apologize for the
            inconvenience.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 February 11 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.4.2, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2022 January 18 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.4.1, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2021 December 11 </v-card-subtitle>
          <v-card-text class="text--primary">
            The new, 2.4.0, version of the WebAccess/DMP has been released.
            Details can be found in Release Notes, which are available
            <a href="https://docs.wadmp.com/release-notes/">here</a>.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2021 December 10 </v-card-subtitle>
          <v-card-text class="text--primary">
            In the upcoming days, we are going to do improvement and maintenance
            changes to our servers. This may lead to some short-term outages of
            the WebAccess/DMP service. We apologize for this inconvenience.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2021 October 8 </v-card-subtitle>
          <v-card-text class="text--primary">
            The availability of WebAccess/DMP has been completely restored. If
            you are still experiencing issues please contact support at
            <a href="https://www.advantech.com/support"
              >www.advantech.com/support</a
            >.
          </v-card-text>
        </v-card>
        <v-card class="pa-6 ma-2">
          <v-card-subtitle class="pb-0"> 2021 October 5 </v-card-subtitle>
          <v-card-text class="text--primary">
            You may experience the service is slow. That is due to an abnormal
            number of requests caused by a certificate renewal. We know about
            the problem and are implementing necessary improvements to get the
            service back to operational condition.
          </v-card-text>
        </v-card>

        <!-- </div>     -->
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Intro from "./components/Intro.vue";
import statusData from "./status.json";

export default {
  name: "App",

  components: {
    Intro,
  },

  data: () => ({
    status: statusData,
  }),
};
</script>
